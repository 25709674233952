import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_CENTER_SURVEY_DATA = 'GET_CENTER_SURVEY_DATA'
const GET_CENTER_SURVEY_DATA_SUCCESS = 'GET_CENTER_SURVEY_DATA_SUCCESS'
const GET_CENTER_SURVEY_DATA_RESET = 'GET_CENTER_SURVEY_DATA_RESET'
const GET_CENTER_SURVEY_DATA_FAILURE = 'GET_CENTER_SURVEY_DATA_FAILURE'
const GET_MORE_CENTER_SURVEY_DATA = 'GET_MORE_CENTER_SURVEY_DATA'
const GET_MORE_CENTER_SURVEY_DATA_SUCCESS = 'GET_MORE_CENTER_SURVEY_DATA_SUCCESS'

const GET_PARENT_CENTER_SURVEY_DATA = 'GET_PARENT_CENTER_SURVEY_DATA'
const GET_PARENT_CENTER_SURVEY_DATA_SUCCESS = 'GET_PARENT_CENTER_SURVEY_DATA_SUCCESS'
const GET_PARENT_CENTER_SURVEY_DATA_RESET = 'GET_PARENT_CENTER_SURVEY_DATA_RESET'
const GET_PARENT_CENTER_SURVEY_DATA_FAILURE = 'GET_PARENT_CENTER_SURVEY_DATA_FAILURE'
const GET_MORE_PARENT_CENTER_SURVEY_DATA = 'GET_MORE_PARENT_CENTER_SURVEY_DATA'
const GET_MORE_PARENT_CENTER_SURVEY_DATA_SUCCESS = 'GET_MORE_PARENT_CENTER_SURVEY_DATA_SUCCESS'

const GET_INVESTIGATOR_SURVEY_DATA = 'GET_INVESTIGATOR_SURVEY_DATA'
const GET_INVESTIGATOR_SURVEY_DATA_SUCCESS = 'GET_INVESTIGATOR_SURVEY_DATA_SUCCESS'
const GET_INVESTIGATOR_SURVEY_DATA_RESET = 'GET_INVESTIGATOR_SURVEY_DATA_RESET'
const GET_INVESTIGATOR_SURVEY_DATA_FAILURE = 'GET_INVESTIGATOR_SURVEY_DATA_FAILURE'
const GET_MORE_INVESTIGATOR_SURVEY_DATA = 'GET_MORE_INVESTIGATOR_SURVEY_DATA'
const GET_MORE_INVESTIGATOR_SURVEY_DATA_SUCCESS = 'GET_MORE_INVESTIGATOR_SURVEY_DATA_SUCCESS'

const GET_INVESTIGATOR_SURVEY_SUMMARY_DATA = 'GET_INVESTIGATOR_SURVEY_SUMMARY_DATA'
const GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_SUCCESS = 'GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_SUCCESS'
const GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_RESET = 'GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_RESET'
const GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_FAILURE = 'GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_FAILURE'

const GET_CENTER_SURVEY_SUMMARY_DATA = 'GET_CENTER_SURVEY_SUMMARY_DATA'
const GET_CENTER_SURVEY_SUMMARY_DATA_SUCCESS = 'GET_CENTER_SURVEY_SUMMARY_DATA_SUCCESS'
const GET_CENTER_SURVEY_SUMMARY_DATA_RESET = 'GET_CENTER_SURVEY_SUMMARY_DATA_RESET'
const GET_CENTER_SURVEY_SUMMARY_DATA_FAILURE = 'GET_CENTER_SURVEY_SUMMARY_DATA_FAILURE'

const GET_CENTER_SURVEY_DEEPDIVE_DATA = 'GET_CENTER_SURVEY_DEEPDIVE_DATA'
const GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA = 'GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA'
const GET_CENTER_SURVEY_DEEPDIVE_DATA_SUCCESS = 'GET_CENTER_SURVEY_DEEPDIVE_DATA_SUCCESS'
const GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA_SUCCESS = 'GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA_SUCCESS'
const GET_CENTER_SURVEY_DEEPDIVE_DATA_RESET = 'GET_CENTER_SURVEY_DEEPDIVE_DATA_RESET'
const GET_CENTER_SURVEY_DEEPDIVE_DATA_FAILURE = 'GET_CENTER_SURVEY_DEEPDIVE_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getCenterSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMoreCenterSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MORE_CENTER_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getCenterSurveyDataResetAction = () => ({
  type: GET_CENTER_SURVEY_DATA_RESET,
})

export function getCenterSurveyDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_SURVEY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MORE_CENTER_SURVEY_DATA_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getInvestigatorSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_INVESTIGATOR_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getInvestigatorSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMoreInvestigatorSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MORE_INVESTIGATOR_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getInvestigatorSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getInvestigatorSurveyDataResetAction = () => ({
  type: GET_INVESTIGATOR_SURVEY_DATA_RESET,
})

export function getInvestigatorSurveyDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_INVESTIGATOR_SURVEY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_MORE_INVESTIGATOR_SURVEY_DATA_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getCenterSurveyDeepDiveDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_SURVEY_DEEPDIVE_DATA,
    url: `${process.env.apiUrl}v0/search/getSurveyData/deepdive`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})
export const getCenterSurveyDeepDiveMoreDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA,
    url: `${process.env.apiUrl}v0/search/getSurveyData/deepdive`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getCenterSurveyDeepDiveDataResetAction = () => ({
  type: GET_CENTER_SURVEY_DEEPDIVE_DATA_RESET,
})

export function getCenterSurveyDeepDiveDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_SURVEY_DEEPDIVE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DEEPDIVE_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DEEPDIVE_MORE_DATA_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
        loadMore: true,
      }
    }
    case GET_CENTER_SURVEY_DEEPDIVE_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_DEEPDIVE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getInvestigatorSurveySummaryDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_INVESTIGATOR_SURVEY_SUMMARY_DATA,
    url: `${process.env.apiUrl}v0/search/getInvestigatorSurveySummary`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getInvestigatorSurveySummaryDataResetAction = () => ({
  type: GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_RESET,
})

export function getInvestigatorSurveySummaryDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_INVESTIGATOR_SURVEY_SUMMARY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_INVESTIGATOR_SURVEY_SUMMARY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getCenterSurveySummaryDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_SURVEY_SUMMARY_DATA,
    url: `${process.env.apiUrl}v0/search/getCenterSurveySummary`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getCenterSurveySummaryDataResetAction = () => ({
  type: GET_CENTER_SURVEY_SUMMARY_DATA_RESET,
})

export function getCenterSurveySummaryDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_SURVEY_SUMMARY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_SUMMARY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_SUMMARY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_SURVEY_SUMMARY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getParentCenterSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PARENT_CENTER_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getParentCenterSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMoreParentCenterSurveyDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MORE_PARENT_CENTER_SURVEY_DATA,
    url: `${process.env.apiUrl}v0/search/getParentCenterSurveyData`,
    method: 'GET',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getParentCenterSurveyDataResetAction = () => ({
  type: GET_PARENT_CENTER_SURVEY_DATA_RESET,
})

export function getParentCenterSurveyDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_CENTER_SURVEY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_CENTER_SURVEY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_CENTER_SURVEY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_MORE_PARENT_CENTER_SURVEY_DATA_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_CENTER_SURVEY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { DatePicker, Table } from 'antd';
import './centersurvey.scss'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import {
  getCenterSurveyDataAction, getCenterSurveyDeepDiveDataAction, getCenterSurveySummaryDataAction, getInvestigatorSurveyDataAction, getInvestigatorSurveySummaryDataAction, getParentCenterSurveyDataAction,
  getMoreCenterSurveyDataAction,
  getMoreInvestigatorSurveyDataAction,
  getMoreParentCenterSurveyDataAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import { getInstituteDeepdiveListAction } from '../InstitutionDetails/logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';

export default function CenterSurvey({
  flag, showques, allianceType, dateFilter,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDate, setDate] = useState('')
  const centerSurveyResponse = useSelector((state) => state.centerSurveyResponse);
  const investigatorSurveyResponse = useSelector((state) => state.investigatorSurveyResponse);
  const getInvestigatorSurveySummaryData = useSelector((state) => state.getInvestigatorSurveySummaryData);
  const getCenterSurveySummaryDataResponse = useSelector((state) => state.getCenterSurveySummaryDataResponse);
  const instituteDeepdiveList = useSelector((store) => store.instituteDeepdiveList);
  const getParentCenterSurveyDataResponse = useSelector((store) => store.getParentCenterSurveyDataResponse);
  const [deepdiveList, setDeepDiveList] = useState([])
  const [from, setFrom] = useState(1)

  const onSurveyNameClick = (record) => {
    dispatch(getCenterSurveyDeepDiveDataAction({ survey_id: record.survey_id, dataset: 'survey_questions_list' }));
    const params = {
      sentOn: record.participant_initial_date,
      sentTo: record.contact_first_name,
      respondedOn: record.participant_completed_date,
      surveyName: record.survey_name,
      response_id: record.response_id,
      contact_id: record.contact_id,
      survey_id: record.survey_id,
    };
    history.push(`/search/deepdives/surveydeepdive?${stringify(params)}`)
  }

  useEffect(() => {
    if (instituteDeepdiveList.flag) {
      setDeepDiveList(instituteDeepdiveList)
    }
  }, [JSON.stringify(instituteDeepdiveList)])

  const surveyProfileResp = deepdiveList?.data?.data?.profile_responses
  useEffect(() => {
    if (flag === 'SITE' && showques === false) {
      dispatch(getCenterSurveyDataAction({
        site_id: parse(location.search).id, dataset: 'survey_participants', from: 1, size: 10,
      }));
      dispatch(getCenterSurveySummaryDataAction({ site_id: parse(location.search).id, dataset: 'survey_participants' }))
    } else {
      dispatch(getInvestigatorSurveyDataAction({
        pi_id: parse(location.search).id, dataset: 'survey_participants', from: 1, size: 10,
      }));
      dispatch(getInvestigatorSurveySummaryDataAction({ pi_id: parse(location.search).id, dataset: 'survey_participants' }))
    }
    if (flag === 'SITE' && showques) {
      dispatch(getInstituteDeepdiveListAction({ id: parse(location.search).id }));
      dispatch(getCenterSurveyDataAction({
        site_id: parse(location.search).id, dataset: 'survey_participants', from: 1, size: 10,
      }));
      dispatch(getCenterSurveySummaryDataAction({ site_id: parse(location.search).id, dataset: 'survey_participants' }))
    }

    if (flag === 'PARENT_SITE' && showques) {
      if (allianceType === 'All') {
        dispatch(getParentCenterSurveyDataAction({
          site_id: parse(location.search).id,
          dataset: 'survey_participants',
          parent_flag: true,
          type: 'All',
          filters: JSON.stringify({
            date: dateFilter,
          }),
          from: 1,
          size: 10,
        }))
      } else if (allianceType === 'Alliance') {
        dispatch(getParentCenterSurveyDataAction({
          site_id: parse(location.search).id,
          dataset: 'survey_participants',
          parent_flag: true,
          type: 'Alliance',
          filters: JSON.stringify({
            date: dateFilter,
          }),
          from: 1,
          size: 10,
        }))
      } else {
        dispatch(getParentCenterSurveyDataAction({
          site_id: parse(location.search).id,
          dataset: 'survey_participants',
          parent_flag: true,
          type: 'Non-Alliance',
          filters: JSON.stringify({
            date: dateFilter,
          }),
          from: 1,
          size: 10,
        }))
      }
    }
  }, [parse(location.search).id, allianceType, dateFilter]);

  const columns = [
    {
      title: 'Sent on',
      dataIndex: 'participant_initial_date',
      key: 'sentOn',
    },
    {
      title: 'Survey name',
      dataIndex: 'survey name',
      key: 'surveyName',
      render: (_, record) => <a onClick={() => onSurveyNameClick(record)} role="presentation">{record.survey_name}</a>,
    },
    {
      title: 'Participant interest',
      dataIndex: 'participant_interest',
      key: 'participant_interest',
    },
    {
      title: 'Participant decline reason',
      dataIndex: 'participant_decline_reason',
      key: 'participant_decline_reason',
    },
    {
      title: 'Consent',
      dataIndex: 'concent',
      key: 'concent',
    },
    {
      title: 'Sent to',
      dataIndex: 'contact_info',
      key: 'sentTO',
      render: (text) => <div>{text.contact_first_name} {text.contact_last_name}</div>,
    },
    {
      title: 'Responded on',
      dataIndex: 'participant_completed_date',
      key: 'respondedOn',
    },
  ]

  const onDateChange = (date, dateString) => {
    setDate(dateString)
  };

  const loadMoreRowsForPiSurvey = () => {
    setFrom(from + 1)
    dispatch(getMoreInvestigatorSurveyDataAction({
      pi_id: parse(location.search).id,
      dataset: 'survey_participants',
      from: from + 1,
      size: 10,
    }))
  }

  const loadMoreRowsForCenterSurvey = () => {
    setFrom(from + 1)
    dispatch(getMoreCenterSurveyDataAction({
      site_id: parse(location.search).id,
      dataset: 'survey_participants',
      from: from + 1,
      size: 10,
    }));
  }

  const loadMoreRowsForParentSurvey = () => {
    setFrom(from + 1)
    if (allianceType === 'All') {
      dispatch(getMoreParentCenterSurveyDataAction({
        site_id: parse(location.search).id,
        dataset: 'survey_participants',
        parent_flag: true,
        type: 'All',
        filters: JSON.stringify({
          date: dateFilter,
        }),
        from: from + 1,
        size: 10,
      }))
    } else if (allianceType === 'Alliance') {
      dispatch(getMoreParentCenterSurveyDataAction({
        site_id: parse(location.search).id,
        dataset: 'survey_participants',
        parent_flag: true,
        type: 'Alliance',
        filters: JSON.stringify({
          date: dateFilter,
        }),
        from: from + 1,
        size: 10,
      }))
    } else {
      dispatch(getMoreParentCenterSurveyDataAction({
        site_id: parse(location.search).id,
        dataset: 'survey_participants',
        parent_flag: true,
        type: 'Non-Alliance',
        filters: JSON.stringify({
          date: dateFilter,
        }),
        from: from + 1,
        size: 10,
      }))
    }
  }

  return (
    <div className="header-wrapper">
      <div className="main-wrapper survey">
        <div className="date-wrapper">
          {
            flag !== 'PARENT_SITE' && (
              <DatePicker
                onChange={onDateChange}
                picker="year"
              />
            )
          }
        </div>
        <div className="tab-ques-wrap-content-card survey-content">
          <Loader loading={centerSurveyResponse.loading || investigatorSurveyResponse.loading || getInvestigatorSurveySummaryData.loading || getCenterSurveySummaryDataResponse.loading} error={centerSurveyResponse.error || investigatorSurveyResponse.error || getInvestigatorSurveySummaryData.error || getCenterSurveySummaryDataResponse.error}>
            {(flag === 'PI') && (
              <div className="survey-content-card">
                <div className="survey-content-card-title">
                  Survey Summary
                </div>
                <div className="survey-content-card-data">
                  <div className="survey-content-card-data-left">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.surveys_sent} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.surveys_responded} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.percent_of_surveys_responded ? getInvestigatorSurveySummaryData?.data?.data?.percent_of_surveys_responded?.toFixed(2) : '-'} </div>
                    </div>
                  </div>
                  <div className="survey-content-card-data-center">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent in last 2 years </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.surveys_sent_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded  in last 2 years </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.surveys_responded_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded in last 2 years </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.percent_of_surveys_responded_in_last_2_year ? getInvestigatorSurveySummaryData?.data?.data?.percent_of_surveys_responded_in_last_2_year.toFixed(2) : '-'} </div>
                    </div>
                  </div>

                  <div className="survey-content-card-data-right">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # times shortlisted for survey approach </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.pi_count} </div>
                    </div>
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # times unresponsive to survey outreach </div>
                      <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.participant_log_status} </div>
                    </div>

                    <div className="survey-content-card-data-right">
                      <div className="titles-wrap">
                        <div className="titles-wrap-text"> # times recommended for QV </div>
                        <div className="titles-wrap-value"> {getInvestigatorSurveySummaryData?.data?.data?.participant_sponsor_approval} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(flag === 'SITE') && (
              <div className="survey-content-card">
                <div className="survey-content-card-title">
                  Survey Summary
                </div>
                <div className="survey-content-card-data">
                  <div className="survey-content-card-data-left">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.surveys_sent} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.surveys_responded} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.percent_of_surveys_responded ? getCenterSurveySummaryDataResponse?.data?.data?.percent_of_surveys_responded.toFixed(2) : '-'} </div>
                    </div>
                  </div>
                  <div className="survey-content-card-data-center">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent in last 2 years </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.surveys_sent_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded  in last 2 years </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.surveys_responded_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded in last 2 years </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.percent_of_surveys_responded_in_last_2_year.toFixed(2)} </div>
                    </div>
                  </div>

                  <div className="survey-content-card-data-right">

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # times unresponsive to survey outreach </div>
                      <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.participant_log_status} </div>
                    </div>

                    <div className="survey-content-card-data-right">
                      <div className="titles-wrap">
                        <div className="titles-wrap-text"> # times recommended for QV </div>
                        <div className="titles-wrap-value"> {getCenterSurveySummaryDataResponse?.data?.data?.participant_sponsor_approval} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(flag === 'PARENT_SITE') && (
              <div className="survey-content-card">
                <div className="survey-content-card-title">
                  Survey Summary
                </div>
                <div className="survey-content-card-data">
                  <div className="survey-content-card-data-left">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.surveys_sent} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.surveys_responded} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.percent_of_surveys_responded ? (getParentCenterSurveyDataResponse?.data?.survey_summary?.percent_of_surveys_responded).toFixed(2) : '-'} </div>
                    </div>
                    <div className="titles-wrap">
                      <div> Average number of days to surveys responded</div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.survey_responded_avg_days ? getParentCenterSurveyDataResponse?.data?.survey_summary?.survey_responded_avg_days.toFixed(2) : '-'} </div>
                    </div>
                  </div>
                  <div className="survey-content-card-data-center">
                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys sent in last 2 years </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.surveys_sent_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # of surveys responded  in last 2 years </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.surveys_responded_in_last_2_year} </div>
                    </div>

                    <div className="titles-wrap">
                      <div> % of surveys responded in last 2 years </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.percent_of_surveys_responded_in_last_2_year ? getParentCenterSurveyDataResponse?.data?.survey_summary?.percent_of_surveys_responded_in_last_2_year.toFixed(2) : '-'} </div>
                    </div>
                  </div>

                  <div className="survey-content-card-data-right">

                    <div className="titles-wrap">
                      <div className="titles-wrap-text"> # times unresponsive to survey outreach </div>
                      <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.participant_log_status} </div>
                    </div>

                    <div className="survey-content-card-data-right">
                      <div className="titles-wrap">
                        <div className="titles-wrap-text"> # times recommended for QV </div>
                        <div className="titles-wrap-value"> {getParentCenterSurveyDataResponse?.data?.survey_summary?.participant_sponsor_approval} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(flag === 'PARENT_SITE') && (
              <div className="survey-content-card">
                <div className="survey-type">
                  <div className="survey-type-wrappper">
                    <div> Surveys By Type </div>
                    <div> Pre-Award </div>
                    <div> Post-Award </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> # Surveys Sent </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.survey_sent} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.survey_sent} </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> # Surveys Responded </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.survey_responded} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.survey_responded} </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> # Surveys Interested </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.survey_interested} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.survey_interested} </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> # Surveys Not Interested </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.survey_not_interested} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.survey_not_interested} </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> % of Surveys Interested </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.percent_survey_interested ? getParentCenterSurveyDataResponse?.data?.pre_award_survey?.percent_survey_interested.toFixed(2) : '-'} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.percent_survey_interested ? getParentCenterSurveyDataResponse?.data?.post_award_survey?.percent_survey_interested.toFixed(2) : '-'} </div>
                  </div>
                  <div className="survey-type-wrappper">
                    <div> % of Surveys Responded </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.pre_award_survey?.percent_survey_responded ? getParentCenterSurveyDataResponse?.data?.pre_award_survey?.percent_survey_responded.toFixed(2) : '-'} </div>
                    <div> {getParentCenterSurveyDataResponse?.data?.post_award_survey?.percent_survey_responded ? getParentCenterSurveyDataResponse?.data?.post_award_survey?.percent_survey_responded.toFixed(2) : '-'} </div>
                  </div>
                </div>

                <div className="survey-indication">
                  <div className="survey-indication-wrappper">
                    <div className="survey-indication-head">
                      <div className="survey-indication-head-in"> Surveys By Indication </div>
                      <div className="survey-indication-head-in"> # Surveys Sent </div>
                      <div className="survey-indication-head-in"> # Surveys Responded </div>
                      <div className="survey-indication-head-in"> # Surveys Interested </div>
                      <div className="survey-indication-head-in"> # Surveys Not Interested </div>
                      <div className="survey-indication-head-in"> % of Surveys Interested </div>
                      <div className="survey-indication-head-in"> % of Surveys Responded </div>
                    </div>
                    {getParentCenterSurveyDataResponse?.data?.survey_by_indicaton?.map((itm) => {
                      return (
                        <div className="survey-indication-content">
                          <div className="survey-indication-content-in">{itm.indication}</div>
                          <div className="survey-indication-content-in"> {itm?.survey_sent} </div>
                          <div className="survey-indication-content-in"> {itm?.survey_responded} </div>
                          <div className="survey-indication-content-in"> {itm?.survey_interested} </div>
                          <div className="survey-indication-content-in"> {itm?.survey_not_interested} </div>
                          <div className="survey-indication-content-in"> {itm?.percent_survey_interested ? itm?.percent_survey_interested : '-'} </div>
                          <div className="survey-indication-content-in"> {itm?.percent_survey_responded ? itm?.percent_survey_responded.toFixed(2) : '-'} </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

            )}
            <>
              {flag === 'SITE' && (
                <>
                  <div className="survey-question-and-data">
                    <div className="survey-question-content-inner-data">
                      {surveyProfileResp && surveyProfileResp.filter((f) => f.parent_category === 'Survey').map((itm) => {
                        return (
                          <div className="survey-question-content-inner-data-in">
                            <div className="survey-question-content-inner-data-in-title">{itm.question_text}</div>
                            <div className="survey-question-content-inner-data-in-value">{itm.response}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="question-wrap survey-content-wrap card">
                    <LazyLoadDiv
                      className="survey-card"
                      id="pi-list-in-search"
                      total={centerSurveyResponse.data.total}
                      currentTotal={(centerSurveyResponse.data.data || []).length}
                      loadMoreRows={() => loadMoreRowsForCenterSurvey()}
                      height="calc(100vh - 400px)"
                    >
                      <Table
                        columns={columns}
                        dataSource={isDate.length === 0 ? centerSurveyResponse.data.data : centerSurveyResponse.flag && centerSurveyResponse.data.data.filter((itm) => {
                          return itm.participant_last_email_sent_date.split('-')[0] === isDate;
                        })}
                        pagination={false}
                      />
                    </LazyLoadDiv>
                  </div>
                </>
              )}
              {flag === 'PI' && (
              <LazyLoadDiv
                className="card-list scrollbar card"
                id="pi-list-in-search"
                total={investigatorSurveyResponse.data.total}
                currentTotal={(investigatorSurveyResponse.data.data || []).length}
                loadMoreRows={() => loadMoreRowsForPiSurvey()}
                height="calc(100vh - 400px)"
              >
                <Table
                  columns={columns}
                  dataSource={isDate.length === 0 ? investigatorSurveyResponse.data.data : investigatorSurveyResponse.flag && investigatorSurveyResponse.data.data.filter((itm) => {
                    return itm.participant_last_email_sent_date.split('-')[0] === isDate
                  })}
                  pagination={false}
                />
              </LazyLoadDiv>
              )}
              {flag === 'PARENT_SITE' && (
                <>
                  <div className="question-wrap survey-content-wrap card">
                    <Loader loading={getParentCenterSurveyDataResponse.loading}>
                      <LazyLoadDiv
                        className="survey-card"
                        id="pi-list-in-search"
                        total={getParentCenterSurveyDataResponse.data.total}
                        currentTotal={(getParentCenterSurveyDataResponse.data.data || []).length}
                        loadMoreRows={() => loadMoreRowsForParentSurvey()}
                        height="calc(100vh - 400px)"
                      >
                        <Table
                          columns={columns}
                          dataSource={getParentCenterSurveyDataResponse.data.data}
                          pagination={false}
                        />
                      </LazyLoadDiv>
                    </Loader>
                  </div>
                </>
              )}
            </>
          </Loader>
        </div>
      </div>
    </div>
  )
}

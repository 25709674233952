import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const COPY_PROJECTS_QUERY_DATA = 'COPY_PROJECTS_QUERY_DATA'
const COPY_PROJECTS_QUERY_DATA_SUCCESS = 'COPY_PROJECTS_QUERY_DATA_SUCCESS'
const COPY_PROJECTS_QUERY_DATA_RESET = 'COPY_PROJECTS_QUERY_DATA_RESET'
const COPY_PROJECTS_QUERY_DATA_FAILURE = 'COPY_PROJECTS_QUERY_DATA_FAILURE'

const COPY_PROJECT_ANALYSIS = 'COPY_PROJECT_ANALYSIS'
const COPY_PROJECT_ANALYSIS_SUCCESS = 'COPY_PROJECT_ANALYSIS_SUCCESS'
const COPY_PROJECT_ANALYSIS_RESET = 'COPY_PROJECT_ANALYSIS_RESET'
const COPY_PROJECT_ANALYSIS_FAILURE = 'COPY_PROJECT_ANALYSIS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getCopyQueryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: COPY_PROJECTS_QUERY_DATA,
    url: `${process.env.apiUrl}v0/queries/copyQuery?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Query copied successfully',
  hideNotification: false, // hide notification on error
})

export const getCopyQueryResetAction = () => ({
  type: COPY_PROJECTS_QUERY_DATA_RESET,
})

export function getCopyQueryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COPY_PROJECTS_QUERY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case COPY_PROJECTS_QUERY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
        sameAnalysis: action.meta.sameAnalysis,
      }
    }
    case COPY_PROJECTS_QUERY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case COPY_PROJECTS_QUERY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Copy Analysis
export const copyProjectAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: COPY_PROJECT_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/getanalysis`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})
export function copyProjectAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COPY_PROJECT_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case COPY_PROJECT_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case COPY_PROJECT_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case COPY_PROJECT_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

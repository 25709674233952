import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_COMMENTS = 'GET_COMMENTS'
const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
const GET_COMMENTS_RESET = 'GET_COMMENTS_RESET'
const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE'

const GET_COMMENTS_COUNTS = 'GET_COMMENTS_COUNTS'
const GET_COMMENTS_COUNTS_SUCCESS = 'GET_COMMENTS_COUNTS_SUCCESS'
const GET_COMMENTS_COUNTS_RESET = 'GET_COMMENTS_COUNTS_RESET'
const GET_COMMENTS_COUNTS_FAILURE = 'GET_COMMENTS_COUNTS_FAILURE'

const CREATE_COMMENT = 'CREATE_COMMENT'
const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
const CREATE_COMMENT_RESET = 'CREATE_COMMENT_RESET'
const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE'

const UPDATE_COMMENT = 'UPDATE_COMMENT'
const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS'
const UPDATE_COMMENT_RESET = 'UPDATE_COMMENT_RESET'
const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE'

const DELETE_COMMENT = 'DELETE_COMMENT'
const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
const DELETE_COMMENT_RESET = 'DELETE_COMMENT_RESET'
const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE'

const RESOLVE_COMMENT = 'RESOLVE_COMMENT'
const RESOLVE_COMMENT_SUCCESS = 'RESOLVE_COMMENT_SUCCESS'
const RESOLVE_COMMENT_RESET = 'RESOLVE_COMMENT_RESET'
const RESOLVE_COMMENT_FAILURE = 'RESOLVE_COMMENT_FAILURE'

const GET_COMMENTS_FOR_APPROVAL = 'GET_COMMENTS_FOR_APPROVAL'
const GET_COMMENTS_FOR_APPROVAL_SUCCESS = 'GET_COMMENTS_FOR_APPROVAL_SUCCESS'
const GET_COMMENTS_FOR_APPROVAL_RESET = 'GET_COMMENTS_FOR_APPROVAL_RESET'
const GET_COMMENTS_FOR_APPROVAL_FAILURE = 'GET_COMMENTS_FOR_APPROVAL_FAILURE'

const CREATE_COMMENT_FOR_APPROVAL = 'CREATE_COMMENT_FOR_APPROVAL'
const CREATE_COMMENT_FOR_APPROVAL_SUCCESS = 'CREATE_COMMENT_FOR_APPROVAL_SUCCESS'
const CREATE_COMMENT_FOR_APPROVAL_RESET = 'CREATE_COMMENT_FOR_APPROVAL_RESET'
const CREATE_COMMENT_FOR_APPROVAL_FAILURE = 'CREATE_COMMENT_FOR_APPROVAL_FAILURE'

const UPDATE_COMMENT_FOR_APPROVAL = 'UPDATE_COMMENT_FOR_APPROVAL'
const UPDATE_COMMENT_FOR_APPROVAL_SUCCESS = 'UPDATE_COMMENT_FOR_APPROVAL_SUCCESS'
const UPDATE_COMMENT_FOR_APPROVAL_RESET = 'UPDATE_COMMENT_FOR_APPROVAL_RESET'
const UPDATE_COMMENT_FOR_APPROVAL_FAILURE = 'UPDATE_COMMENT_FOR_APPROVAL_FAILURE'

const DELETE_COMMENT_FOR_APPROVAL = 'DELETE_COMMENT_FOR_APPROVAL'
const DELETE_COMMENT_FOR_APPROVAL_SUCCESS = 'DELETE_COMMENT_FOR_APPROVAL_SUCCESS'
const DELETE_COMMENT_FOR_APPROVAL_RESET = 'DELETE_COMMENT_FOR_APPROVAL_RESET'
const DELETE_COMMENT_FOR_APPROVAL_FAILURE = 'DELETE_COMMENT_FOR_APPROVAL_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getCommentsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COMMENTS,
    url: `${process.env.apiUrl}v0/comment/getComment`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getCommentsResetAction = () => ({
  type: GET_COMMENTS_RESET,
})

export const getCommentsCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COMMENTS_COUNTS,
    url: `${process.env.apiUrl}v0/comment/getCommentCount`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getCommentsCountsResetAction = () => ({
  type: GET_COMMENTS_COUNTS_RESET,
})

export const createCommentAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: CREATE_COMMENT,
    url: `${process.env.apiUrl}v0/comment/createComment`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment added successfully',
  hideNotification: false, // hide notification on error
})

export const createCommentResetAction = () => ({
  type: CREATE_COMMENT_RESET,
})

export const deleteCommentAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: DELETE_COMMENT,
    url: `${process.env.apiUrl}v0/comment/deleteComment?${stringify(payload.params)}`,
    method: 'DELETE',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment deleted successfully',
  hideNotification: false, // hide notification on error
})

export const deleteCommentResetAction = () => ({
  type: DELETE_COMMENT_RESET,
})

export const resolveCommentAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: RESOLVE_COMMENT,
    url: `${process.env.apiUrl}v0/comment/resolvedComment?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment marked as resolved successfully',
  hideNotification: false, // hide notification on error
})

export const resolveCommentResetAction = () => ({
  type: RESOLVE_COMMENT_RESET,
})

export const updateCommentAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_COMMENT,
    url: `${process.env.apiUrl}v0/comment/editComment?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment updated successfully',
  hideNotification: false, // hide notification on error
})

export const updateCommentResetAction = () => ({
  type: UPDATE_COMMENT_RESET,
})

export const getCommentsForApprovalAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COMMENTS_FOR_APPROVAL,
    url: `${process.env.apiUrl}v0/comment/getCommentForApproval`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getCommentsForApprovalResetAction = () => ({
  type: GET_COMMENTS_FOR_APPROVAL_RESET,
})

export const createCommentForApprovalAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: CREATE_COMMENT_FOR_APPROVAL,
    url: `${process.env.apiUrl}v0/comment/createCommentForApproval`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment added successfully',
  hideNotification: false, // hide notification on error
})

export const createCommentForApprovalResetAction = () => ({
  type: CREATE_COMMENT_FOR_APPROVAL_RESET,
})

export const deleteCommentForApprovalAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: DELETE_COMMENT_FOR_APPROVAL,
    url: `${process.env.apiUrl}v0/comment/deleteCommentForApproval?${stringify(payload.params)}`,
    method: 'DELETE',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment deleted successfully',
  hideNotification: false, // hide notification on error
})

export const deleteCommentForApprovalResetAction = () => ({
  type: DELETE_COMMENT_FOR_APPROVAL_RESET,
})

export const updateCommentForApprovalAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_COMMENT_FOR_APPROVAL,
    url: `${process.env.apiUrl}v0/comment/editCommentForApproval?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Comment updated successfully',
  hideNotification: false, // hide notification on error
})

export const updateCommentForApprovalResetAction = () => ({
  type: UPDATE_COMMENT_FOR_APPROVAL_RESET,
})

export function getCommentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COMMENTS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getCommentsCountsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_COUNTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COMMENTS_COUNTS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createCommentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_COMMENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_COMMENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_COMMENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_COMMENT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function deleteCommentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_COMMENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_COMMENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_COMMENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_COMMENT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function resolveCommentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESOLVE_COMMENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case RESOLVE_COMMENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case RESOLVE_COMMENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case RESOLVE_COMMENT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function updateCommentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_COMMENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COMMENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_COMMENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COMMENT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getCommentsForApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS_FOR_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_FOR_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COMMENTS_FOR_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_COMMENTS_FOR_APPROVAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createCommentForApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_COMMENT_FOR_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_COMMENT_FOR_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_COMMENT_FOR_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_COMMENT_FOR_APPROVAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function deleteCommentForApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_COMMENT_FOR_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_COMMENT_FOR_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_COMMENT_FOR_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_COMMENT_FOR_APPROVAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function updateCommentForApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_COMMENT_FOR_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COMMENT_FOR_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_COMMENT_FOR_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COMMENT_FOR_APPROVAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

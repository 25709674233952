import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import {
  Button, Popover, Checkbox, Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { parse, stringify } from 'query-string';
import Loader from '../../../common/components/Loader';
import Bookmark from '../../../common/components/Bookmark';
import { getDatasetListDataAction } from '../SearchResults/logic';
import {
  getProjectAnalysisAction,
} from '../../components/ProjectDetails/logic';
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';
import './compare.scss'
import ReadMore from '../../../common/components/ReadMore';

export default function CompareSites({ ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [listData, setListData] = useState([]);
  const [analysisData, setAnalysisData] = useState({});
  const [compareList, setCompareList] = useState(parse(props.location.search).compareList ? parse(props.location.search).compareList.split(',') : []);
  const parsedSearch = parse(props.location.search)
  if (compareList) {
    delete parsedSearch.compareList
  }
  const { lastUrlPath, locationSearch } = parsedSearch
  const compareInvestigatorListData = useSelector((state) => state.compareInvestigatorListData);
  const [visible, setVisible] = useState(false);
  const [checkedValueList, setCheckedValueList] = useState(['interventional_studies', 'parexel_studies', 'tier', 'reliability', 'quality', 'last_trial_start_date'])
  const [allowed, setAllowed] = useState([]);
  const projectAnalysisData = useSelector((state) => state.projectAnalysisData);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (parse(parsedSearch.locationSearch).analysis_id) {
      dispatch(
        getProjectAnalysisAction({
          analysis_id: parse(parsedSearch.locationSearch).analysis_id,
        }),
      );
    }
  }, []);

  useEffect(() => {
    let currentAnalysis;
    if (projectAnalysisData.flag) {
      currentAnalysis = projectAnalysisData.data.data.filter((itm) => itm.analysis_id === parse(parsedSearch.locationSearch).analysis_id)
      setAnalysisData(currentAnalysis[0]);
    }
  }, [JSON.stringify(projectAnalysisData)])

  useEffect(() => {
    if (compareInvestigatorListData.flag) {
      setListData(compareInvestigatorListData.data.data);
    }
  }, [JSON.stringify(compareInvestigatorListData)]);

  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(createBookmarkResetAction())
      dispatch(
        getDatasetListDataAction({
          dataset: 'centers',
          doc_ids: compareList.join(','),
          // doc_id: compareList,
        }),
      );
      dispatch(
        getProjectAnalysisAction({
          analysis_id: parse(parsedSearch.locationSearch).analysis_id,
        }),
      );
    }
    if (deleteBookmark.flag) {
      dispatch(deleteBookmarkResetAction())
      dispatch(
        getDatasetListDataAction({
          dataset: 'centers',
          doc_ids: compareList.join(','),
          // doc_id: compareList,
        }),
      );
      dispatch(
        getProjectAnalysisAction({
          analysis_id: parse(parsedSearch.locationSearch).analysis_id,
        }),
      );
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  useEffect(() => {
    if (compareList) {
      dispatch(
        getDatasetListDataAction({
          dataset: 'centers',
          doc_ids: compareList.join(','),
          // doc_id: compareList,
        }),
      );
    }
  }, [compareList]);

  const header = [
    {
      header_name: 'Center Name',
      key: 'investigator',
      className: 'investigator',
    },
    {
      header_name: 'Primary Center',
      key: 'affiliation',
      className: 'affiliation',
    },
    {
      header_name: '# of interventional studies',
      key: 'interventional_studies',
      className: 'interventional_studies',
    },
    {
      header_name: '# of parexel studies',
      key: 'parexel_studies',
      className: 'parexel_studies',
    },
    {
      header_name: 'Tier',
      key: 'tier',
      className: 'tier',
    },
    {
      header_name: 'Reliability',
      key: 'reliability',
      className: 'reliability',
    },
    {
      header_name: 'Quality',
      key: 'quality',
      className: 'quality',
    },
    {
      header_name: 'Total PIs',
      key: 'total_pis',
      className: 'total_pis',
    },
    {
      header_name: 'Indications',
      key: 'indications',
      className: 'indications',
    },
    {
      header_name: 'Latest Trial Start Date',
      key: 'last_trial_start_date',
    },
    {
      header_name: 'button',
      className: 'header-button',
    },

  ];

  const filterHeader = [
    {
      header_name: '# of interventional studies',
      key: 'interventional_studies',
      className: 'interventional_studies',
    },
    {
      header_name: '# of parexel studies',
      key: 'parexel_studies',
      className: 'parexel_studies',
    },
    {
      header_name: 'Tier',
      key: 'tier',
      className: 'tier',
    },
    {
      header_name: 'Reliability',
      key: 'reliability',
      className: 'reliability',
    },
    {
      header_name: 'Quality',
      key: 'quality',
      className: 'quality',
    },
    {
      header_name: 'Latest Trial Start Date',
      key: 'last_trial_start_date',
    },
  ];

  const handleBackButton = () => {
    history.goBack();
  };

  const handleRemove = (id) => {
    const value = compareList.filter((e) => e !== id);
    setCompareList(value)
  };

  const handleAddInvestigator = () => {
    const params = {
      compareList: compareList.join(','),
      compareSiteFlag: true,
    };
    const constructUrl = `${lastUrlPath}?${locationSearch}&${stringify(params)}`
    history.push(constructUrl)
  };

  const addInvestigatorDiv = () => {
    return (
      <div className="compare-row-content">
        <div className="compare-row-content-data card card-person-sec">
          <div className="compare-row-content-data-value compare-row-person-data-value">
            <div className="card-person-img">
              <div className="img-person no-img" />
            </div>
          </div>
          <div className="compare-row-content-data-value remove-btn-clm">
            <Button onClick={handleAddInvestigator}>Add Center</Button>
          </div>
        </div>
      </div>
    )
  }

  const filterListData = listData.map((itm) => {
    const filtered = Object.keys(itm)
      .filter((key) => !allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = itm[key];
        return obj;
      }, {});
    return filtered
  })

  const onChange = (e, checkedValues) => {
    if (e.target.checked) {
      setCheckedValueList([...checkedValueList, checkedValues])
    } else {
      const newCheckedVal = checkedValueList.filter((itm) => itm !== checkedValues)
      setCheckedValueList(newCheckedVal)
    }
  };

  const OnApplyClick = () => {
    setVisible(false);
    const temp = ['interventional_studies', 'parexel_studies', 'tier', 'reliability', 'quality', 'last_trial_start_date'];
    setAllowed(temp.filter((itm) => checkedValueList.indexOf(itm) === -1))
  }

  return (
    <div className="run-query-page">
      <div className="run-query-page-header">
        <div className="logo-backbtn-section ">
          <div className="landscape-logo">
            <div className="landscape-logo-img" />
          </div>
          <div className="back-button">
            <Button
              onClick={handleBackButton}
              tabIndex={0}
              role="button"
              onKeyDown={null}
              className="header-back-btn"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      <Loader loading={compareInvestigatorListData.loading} error={compareInvestigatorListData.error}>
        <div className="page-container run-query-page-container">
          <div className="card-details">
            <div className="card-details-header use-flex project-details-main">
              <div className="card-details-header-title card-details-project-header-title">
                <span className="title-name">Project Name:</span>
                <span className="desc">{parse(location.search).project_name}</span>
              </div>
            </div>
            <div className="card-details-header use-flex project-details-main">
              <div className="card-details-header-title"><span className="title-name">Analysis:</span> <span className="project-name" title={parse(location.search).analysis_name}>{parse(location.search).analysis_name}</span></div>
              {/* <div className="project-detail-name">
                <div className="project-detail-name-value">
                  <span className="project-name">{parse(location.search).analysis_name}</span>
                </div>
              </div> */}
              <div className="project-data-meta-section analysis-meta-section-header">
                <div className="fav">
                  <Bookmark isBookmarked={analysisData.isBookmarked && analysisData.isBookmarked.flag} type="analysis" id={analysisData.analysis_id} bookmark_id={analysisData.isBookmarked && analysisData.isBookmarked.bookmark_id} />
                </div>
                <Tooltip title="Comments">
                  <div className="comments">
                    <div className="comments-img" />
                  </div>
                </Tooltip>
                {
                  // <div className="card-dropdown">
                  //   <div className="card-dropdown-icon" />
                  // </div>
                }
              </div>
            </div>
            <div className="project-data-wrap">
              <div className="show-hide-btn">
                <Popover
                  overlayInnerStyle={{ maxWidth: 'inherit', minWidth: 'inherit', paddingTop: '20px' }}
                  content={(
                    <div className="popover-row">
                      <div className="show-hide-btns">
                        <Button onClick={OnApplyClick} type="primary">
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                  title={(
                    <div className="popover-row-data">
                      {filterHeader.map((itm) => {
                        return (
                          <div className="compare-row-content-data-value">
                            <Checkbox checked={checkedValueList.indexOf(itm.key) !== -1} onChange={(e) => onChange(e, itm.key)}>{itm.header_name}</Checkbox>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                >
                  <div className="border-btn">
                    <Button type="button"> Show/Hide column </Button>
                  </div>
                </Popover>
              </div>
              <div className="project-data-section">
                <div className="run-query-content-section">
                  <div className="run-query-content-card card-country-content">
                    <div className="compare-wrapper">
                      <div className="compare-row-content compare-row-content-header-side">
                        <div className="compare-row-content-data card">
                          {header.filter((itm) => !allowed.includes(itm.key)).map((itm) => {
                            return (
                              <>
                                {itm.header_name === 'button' ? (
                                  <div className="compare-row-content-data-value no-value-div">
                                    &nbsp;
                                  </div>
                                ) : (
                                  // <div className="compare-row-content-data-value"></div>
                                  <div className={`compare-row-content-data-value header-center ${itm.className}`}>
                                    {itm.header_name}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                      {compareList.length && filterListData.map((resp) => {
                        return (
                          <div className="compare-row-content">
                            <div className="compare-row-content-data card">
                              <div className="compare-row-content-data-value compare-row-content-header">
                                <div className="header-wrap">
                                  <MenuUnfoldOutlined />
                                  <a
                                    className="card-content-title pi-wrap"
                                    title={resp.name}
                                    target="_blank"
                                    href={`/search/deepdives?${stringify({
                                      query: resp.name,
                                      id: resp.id,
                                      type: 'SITE',
                                      currentTab: resp.parent_flag ? 'parent_centers' : 'centers',
                                    })}`}
                                    rel="noreferrer noopener"
                                  >
                                    {resp.name}
                                  </a>
                                  <div className="fav">
                                    <Bookmark isBookmarked={resp.isBookmarked && resp.isBookmarked.flag} type="centers" id={resp.id} bookmark_id={resp.isBookmarked && resp.isBookmarked.bookmark_id} />
                                  </div>
                                </div>
                              </div>
                              {resp?.address?.city ? (
                                <div className="compare-row-content-data-value affiliation">
                                  {resp.address.city},{resp.address.state}, {resp.address.country}, {resp.address.zip}
                                </div>
) : <div className="compare-row-content-data-value affiliation dash-nodata">-</div>}
                              {'interventional_studies' in resp && (
                              <div className="compare-row-content-data-value tier">
                                <div className="count-value">{resp.interventional_studies}</div>
                              </div>
                              )}
                              {'parexel_studies' in resp && (
                              <div className="compare-row-content-data-value parexel_studies">
                                <div className="count-value">{resp.parexel_studies}</div>
                              </div>
                              )}
                              {'tier' in resp && (
                              <div className="compare-row-content-data-value tier">
                                <div className="count-value">{resp.tier}</div>
                              </div>
                              )}
                              { 'reliability' in resp && (
                              <div className="compare-row-content-data-value recruitment">
                                {resp.reliability
                                  ? (
                                    <StarRatings
                                      rating={resp.reliability}
                                      starRatedColor="#8A0050"
                                      numberOfStars={3}
                                      starDimension="16px"
                                      starSpacing="1px"
                                      starEmptyColor="#D099B9"
                                    />
                                  )
                                  : <div className="dash-nodata">-</div>}
                              </div>
                              )}
                              {'quality' in resp
                              && (
                              <div className="compare-row-content-data-value performance">
                                { resp.quality ? (
                                  <StarRatings
                                    rating={resp.quality || 0}
                                    starRatedColor="#8A0050"
                                    numberOfStars={3}
                                    starDimension="16px"
                                    starSpacing="1px"
                                    starEmptyColor="#D099B9"
                                  />
                                ) : <div className="dash-nodata">-</div>}
                              </div>
                              )}
                              {'total_number_of_popis' in resp
                              && (
                              <div className="compare-row-content-data-value alliance">
                                {resp.total_number_of_popis
                                && <div className="count-value">{resp.total_number_of_popis}</div>}
                              </div>
                              )}
                              {'indications' in resp && (
                              <div className="compare-row-content-data-value indications">
                                <ReadMore type="text" limit={60}>
                                  {resp.indications && resp.indications.length ? resp.indications.join('; ') : '-'}
                                </ReadMore>
                              </div>
                              )}
                              {'last_trial_start_date' in resp
                              && (
                              <div className="compare-row-content-data-value recruitment">
                                {resp.last_trial_start_date
                                  ? <div className="count-value">{resp.last_trial_start_date}</div>
                                  : <div className="dash-nodata">-</div>}
                              </div>
                              )}
                              <div className="compare-row-content-data-value remove-btn-clm">
                                <Button
                                  onClick={() => handleRemove(resp.id)}
                                  className="remove-btn"
                                  type="primary"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {compareList.length < 3 && [...Array(3 - compareList.length)].map(() => addInvestigatorDiv())}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Popover, Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { parse, stringify } from 'query-string';
import Loader from '../../../common/components/Loader';
import './compare.scss'
import { getRunAnalysisCountsAction, getRunAnalysisCountsResetAction } from '../RunQuery/logic';
import { getProjectAnalysisAction, getProjectAnalysisResetAction } from '../../components/ProjectDetails/logic';
import Bookmark from '../../../common/components/Bookmark';
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';

export default function CompareAnalysis({ ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [compareList, setCompareList] = useState(parse(props.location.search).compareList ? parse(props.location.search).compareList.split(',') : []);
  const parsedSearch = parse(props.location.search)
  if (compareList) {
    delete parsedSearch.compareList
  }
  const { lastUrlPath, locationSearch } = parsedSearch
  const [visible, setVisible] = useState(false);
  const [checkedValueList, setCheckedValueList] = useState(['queryCount', 'centers', 'pis', 'created_on', 'user_name'])
  const [allowed, setAllowed] = useState([])
  const runAnalysisCountsData = useSelector((state) => state.runAnalysisCountsData);
  const projectAnalysisData = useSelector((state) => state.projectAnalysisData);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    return () => {
      dispatch(getRunAnalysisCountsResetAction());
    }
  }, [])
  // useEffect(() => {
  //   if (runAnalysisCountsData.flag) {
  //   }
  // }, [JSON.stringify(runAnalysisCountsData)]);

  // useEffect(() => {
  //   if (projectAnalysisData.flag) {
  //   }
  // }, [JSON.stringify(projectAnalysisData)]);

  useEffect(() => {
    if (compareList) {
      compareList.forEach((idd) => {
        dispatch(
          getRunAnalysisCountsAction({
            analysis_id: idd,
          }, { analysis_id: idd, key: idd }),
        );

        dispatch(
          getProjectAnalysisAction({
            page: 1,
            size: 10,
            analysis_id: idd,
          }, { analysis_id: idd, key: idd }),
        );
      })
    }
  }, [compareList]);

  const header = [
    {
      header_name: 'Analysis',
      key: 'name',
    },
    {
      header_name: '# Queries',
      key: 'queryCount',
    },
    {
      header_name: '# Centers',
      key: 'centers',
    },
    {
      header_name: '# Investigators',
      key: 'pis',
    },
    {
      header_name: 'Created On',
      key: 'created_on',
    },
    {
      header_name: 'Owner',
      key: 'user_name',
    },
    // {
    //   header_name: 'PIs Count',
    //   key: 'pi_count',
    // },
  ];

  const filterHeader = [
    {
      header_name: '# Queries',
      key: 'queryCount',
    },
    {
      header_name: '# Centers',
      key: 'centers',
    },
    {
      header_name: '# Investigators',
      key: 'pis',
    },
    {
      header_name: 'Created On',
      key: 'created_on',
    },
    {
      header_name: 'Owner',
      key: 'user_name',
    },
    // {
    //   header_name: 'PIs Count',
    //   key: 'pi_count',
    // },
  ];

  const handleBackButton = () => {
    dispatch(getProjectAnalysisResetAction())
    history.goBack();
  };

  const handleRemove = (id) => {
    const value = compareList.filter((e) => e !== id);
    setCompareList(value)
  };

  const handleAddAnalysis = () => {
    dispatch(getProjectAnalysisResetAction())
    const params = {
      compareList: compareList.join(','),
      compareFlag: true,
    };
    const constructUrl = `${lastUrlPath}?${locationSearch}&${stringify(params)}`
    history.push(constructUrl)
  };

  const addAnalysisDiv = () => {
    return (
      <div className="compare-row-content">
        <div className="compare-row-content-data card card-person-sec">
          <div className="compare-row-content-data-value compare-row-person-data-value">
            <div className="card-person-img">
              <div className="img-person no-img" />
            </div>
          </div>
          <div className="compare-row-content-data-value remove-btn-clm">
            <Button onClick={handleAddAnalysis}>Add Analysis</Button>
          </div>
        </div>
      </div>
    )
  }

  const showHideData = (itm) => {
    const filtered = Object.keys(itm)
      .filter((key) => !allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = itm[key];
        return obj;
      }, {});
    return filtered
  }

  const filterListData = () => {
    const api1 = runAnalysisCountsData.data;
    const api2 = projectAnalysisData.data;
    if (runAnalysisCountsData.loading || projectAnalysisData.loading) {
      return []
    }
    return Object.keys(api1).map((id) => {
      const final = {
        queryCount: api1[id].length,
        pis: combine(api1[id], 'pis'),
        centers: combine(api1[id], 'centers'),
      }
      if (api2[id]) {
        const output = { ...api2[id][0], ...final }
        return showHideData(output)
        // return output
      }
      return showHideData(final)
    })
  }

  const onChange = (e, checkedValues) => {
    if (e.target.checked) {
      setCheckedValueList([...checkedValueList, checkedValues])
    } else {
      const newCheckedVal = checkedValueList.filter((itm) => itm !== checkedValues)
      setCheckedValueList(newCheckedVal)
    }
  };

  const OnApplyClick = () => {
    setVisible(false);
    const temp = ['queryCount', 'centers', 'pis', 'created_on', 'user_name'];
    setAllowed(temp.filter((itm) => checkedValueList.indexOf(itm) === -1))
  }

  const combine = (data, key) => {
    return data.reduce((v, k) => {
      return v + k[key]
    }, 0)
  }

  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(createBookmarkResetAction())
      if (compareList) {
        compareList.forEach((idd) => {
          dispatch(
            getRunAnalysisCountsAction({
              analysis_id: idd,
            }, { analysis_id: idd, key: idd }),
          );
          dispatch(
            getProjectAnalysisAction({
              page: 1,
              size: 10,
              analysis_id: idd,
            }, { analysis_id: idd, key: idd }),
          );
        })
      }
    }

    if (deleteBookmark.flag) {
      dispatch(deleteBookmarkResetAction())
      if (compareList) {
        compareList.forEach((idd) => {
          dispatch(
            getRunAnalysisCountsAction({
              analysis_id: idd,
            }, { analysis_id: idd, key: idd }),
          );
          dispatch(
            getProjectAnalysisAction({
              page: 1,
              size: 10,
              analysis_id: idd,
            }, { analysis_id: idd, key: idd }),
          );
        })
      }
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  const renderTable = (data) => {
    return data?.map((itm) => {
      return (
        <div className="compare-row-content">
          <div className="compare-row-content-data card">
            {header.filter((head) => !allowed.includes(head.key)).map((c) => {
              if (c.key === 'name') {
                return (
                  <div className="compare-row-content-data-value compare-row-content-header">
                    <div className="header-wrap">
                      <MenuUnfoldOutlined />
                      <div className="pi-wrap">{itm.name}</div>
                      {/* <div className="bookmark">
                        <div className="bookmark-img" />
                      </div> */}
                      <div className="bookmark">
                        <Bookmark isBookmarked={itm.isBookmarked && itm.isBookmarked.flag} type="analysis" id={itm.analysis_id} bookmark_id={itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                      </div>
                    </div>
                  </div>
                )
              }
              return (
                <div className="compare-row-content-data-value">
                  {c.key === 'created_on' ? <div className="count-value">{moment.utc(itm[c.key]).format('YYYY-MM-DD h:mm:ss')}</div> : <div className="count-value">{itm[c.key]}</div>}
                </div>
              )
            })}
            {/* {itm.queryCount && (
            <div className="compare-row-content-data-value query-count">
              <div className="count-value">{itm.queryCount}</div>
            </div>
            )}

            {itm.centers && (
            <div className="compare-row-content-data-value center-count">
              <div className="count-value">{itm.centers}</div>
            </div>
            )}
            {itm.pis && (
            <div className="compare-row-content-data-value pis-count">
              <div className="count-value">{itm.pis}</div>
            </div>
            )}
            {itm.created_on && (
            <div className="compare-row-content-data-value created-at">
              <div className="count-value">{itm.created_on}</div>
            </div>
            )}
            {itm.user_name && (
            <div className="compare-row-content-data-value user-name">
              <div className="count-value">{itm.user_name}</div>
            </div>
            )} */}
            <div className="compare-row-content-data-value remove-btn-clm">
              <Button
                onClick={() => handleRemove(itm.analysis_id)}
                className="remove-btn"
                type="primary"
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      );
    })
  }
  return (
    <div className="run-query-page">
      <div className="run-query-page-header">
        <div className="logo-backbtn-section ">
          <div className="landscape-logo">
            <div className="landscape-logo-img" />
          </div>
          <div className="back-button">
            <Button
              onClick={handleBackButton}
              tabIndex={0}
              role="button"
              onKeyDown={null}
              className="header-back-btn"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      <Loader loading={projectAnalysisData.loading} error={projectAnalysisData.error}>
        <div className="page-container run-query-page-container">
          <div className="card-details">
            <div className="card-details-header use-flex project-details-main">
              <div className="card-details-header-title card-details-project-header-title">
                <span className="title-name">Project Name:</span>
                <span className="desc">{parse(location.search).project_name}</span>
              </div>
            </div>
            {/* <Loader loading={projectAnalysisData.loading} error={projectAnalysisData.error}> */}
            <div className="project-data-wrap">
              <div className="show-hide-btn">
                <Popover
                  content={(
                    <div>
                      {/* <Filters /> */}
                      <div className="show-hide-btns">
                        <Button onClick={OnApplyClick} type="primary">
                          Apply
                        </Button>
                      </div>
                    </div>
          )}
                  title={(
                    <div>
                      {filterHeader.map((itm) => {
                        return (
                          <div className="compare-row-content-data-value">
                            <Checkbox checked={checkedValueList.indexOf(itm.key) !== -1} onChange={(e) => onChange(e, itm.key)}>{itm.header_name}</Checkbox>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                >
                  <Button type="primary"> Show/Hide column </Button>
                </Popover>
              </div>
              <div className="project-data-section">
                <div className="run-query-content-section">
                  <div className="run-query-content-card card-country-content">
                    <div className="compare-wrapper">
                      <div className="compare-row-content compare-row-content-header-side">
                        <div className="compare-row-content-data card">
                          {header.filter((itm) => !allowed.includes(itm.key)).map((itm) => {
                            return (
                              <>
                                {itm.header_name === 'button' ? (
                                  <div className="compare-row-content-data-value no-value-div">
                                    &nbsp;
                                  </div>
                                ) : (
                                  <div className="compare-row-content-data-value">
                                    {itm.header_name}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                      {compareList.length && renderTable(filterListData())}

                      {compareList.length < 3 && [...Array(3 - compareList.length)].map(() => addAnalysisDiv())}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Loader> */}
          </div>
        </div>
      </Loader>
    </div>
  );
}

import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_CENSUS_DATA = 'GET_CENSUS_DATA';
const GET_CENSUS_DATA_CANCEL = 'GET_CENSUS_DATA_CANCEL';
const GET_CENSUS_DATA_SUCCESS = 'GET_CENSUS_DATA_SUCCESS';
const GET_CENSUS_DATA_FAILURE = 'GET_CENSUS_DATA_FAILURE';
const GET_CENSUS_DATA_RESET = 'GET_CENSUS_DATA_RESET';

// Auto Complete cancel action
export const getCensusDataCancelAction = () => ({
  type: GET_CENSUS_DATA_CANCEL,
});

export const getCensusDataResetAction = () => ({
  type: GET_CENSUS_DATA_RESET,
});
// Auto Complete action
export const getCensusDataAction = (payload) => ({
  type: GET_CENSUS_DATA,
  payload,
});

// Auto Complete Success action
const getCensusDataSuccess = (payload) => ({
  type: GET_CENSUS_DATA_SUCCESS,
  payload,
});

export const getCensusDataEpic = (actions$) => actions$.pipe(
  ofType(GET_CENSUS_DATA),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/getCensus?${stringify(action.payload)}`, 'get'),
  ).pipe(
    map((res) => getCensusDataSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_CENSUS_DATA_CANCEL))),
    catchError((err) => of({
      type: GET_CENSUS_DATA_FAILURE,
      payload: err,
    })),
  )),
);

export function getCensusDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENSUS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_CENSUS_DATA_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_CENSUS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    case GET_CENSUS_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state;
  }
}

const GENERIC_ACTION = 'GENERIC_ACTION'

const GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE = 'GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE';

const GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_SUCCESS = 'GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_SUCCESS';
const GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_FAILURE = 'GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_FAILURE';

export const getPurpleLabDataForPIForExperienceAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE,
      url: `${process.env.apiUrl}v0/purplelab/getPurpleLabDataForPi`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export function getPurpleLabDataForPIForExperienceReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_PI_FOR_EXPERIENCE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    // case GET_PURPLE_LAB_DATA_FOR_PI_MORE_SUCCESS: {
    //   return {
    //     data: {
    //       data: [
    //         ...state.data.data,
    //         ...action.payload.data,
    //       ],
    //       total: action.payload.total,
    //     },
    //     loading: false,
    //     flag: true,
    //     error: false,
    //   }
    // }
    default:
      return state;
  }
}

// purple-lab data for site

const GET_PURPLE_LAB_DATA_FOR_SITE_SDOH = 'GET_PURPLE_LAB_DATA_FOR_SITE_SDOH';

const GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_SUCCESS = 'GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_SUCCESS';
const GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_FAILURE = 'GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_FAILURE';
const GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_RESET = 'GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_RESET';

export const getPurpleLabDataForSiteSDOHAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PURPLE_LAB_DATA_FOR_SITE_SDOH,
      url: `${process.env.apiUrl}v0/purplelab/getPurpleLabDataForSite`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getPurpleLabDataForSiteSDOHResetAction = () => ({
  type: GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_RESET,
})

export function getPurpleLabDataForSiteSDOHReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PURPLE_LAB_DATA_FOR_SITE_SDOH: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }

    case GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case GET_PURPLE_LAB_DATA_FOR_SITE_SDOH_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    default:
      return state;
  }
}

import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const COPY_PROJECTS_ANALYSIS_DATA = 'COPY_PROJECTS_ANALYSIS_DATA'
const COPY_PROJECTS_ANALYSIS_DATA_SUCCESS = 'COPY_PROJECTS_ANALYSIS_DATA_SUCCESS'
const COPY_PROJECTS_ANALYSIS_DATA_FAILURE = 'COPY_PROJECTS_ANALYSIS_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const copyAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: COPY_PROJECTS_ANALYSIS_DATA,
    url: `${process.env.apiUrl}v0/analyses/copyAnalysis?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Analysis copied successfully',
  hideNotification: false, // hide notification on error
})

export function copyAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COPY_PROJECTS_ANALYSIS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case COPY_PROJECTS_ANALYSIS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
        sameProject: action.meta.sameProject,
      }
    }
    case COPY_PROJECTS_ANALYSIS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

import React from 'react'

export default function CentersOtherKeyPersonal({ data }) {
  return (
    <div className="studies-filters-wrap">
      {data?.data?.other_personnel?.length ? data.data.other_personnel.map((itm) => {
        return (
          <div className="other-key-personal">
            {/* <div className="other-key-personal-title">{itm.role} {itm.first_name} {itm.last_name}</div> */}
            <div className="other-key-personal-content">
              <div className="other-key-personal-content-card card">
                <div className="other-key-personal-content-card-content">
                  <div className="content-value">
                    <div className="content-value-title">Role: &nbsp;</div>
                    <div className="content-value-desc">{itm.role ? itm.role : '-'}</div>
                  </div>
                  <div className="content-value">
                    <div className="content-value-title">Role at site: &nbsp;</div>
                    <div className="content-value-desc">{itm.role_at_site ? itm.role_at_site : '-'}</div>
                  </div>
                  <div className="content-value">
                    <div className="content-value-title">Name: &nbsp;</div>
                    <div className="content-value-desc">{itm.full_name ? itm.full_name : '-'}</div>
                  </div>
                  <div className="content-value">
                    <div className="content-value-title">Phone Number: &nbsp;</div>
                    <div className="content-value-desc">{itm.mobile_number.length ? itm.mobile_number : '-'}</div>
                  </div>
                  <div className="content-value">
                    <div className="content-value-title">Fax: &nbsp;</div>
                    <div className="content-value-desc">{itm.fax.length ? itm.fax : '-'}</div>
                  </div>
                  <div className="content-value">
                    <div className="content-value-title">Email: &nbsp;</div>
                    <div className="content-value-desc">{itm.email.length ? itm.email : '-'}</div>
                  </div>
                  <div className="combine-div-card">
                    <div className="content-value">
                      <div className="content-value-title">Address: &nbsp; </div>
                      <div className="content-value-desc">

                        {itm?.address ? (
                          <div className="content-child address-field-content">
                            {itm.address?.address_line1 && `${itm.address?.address_line1}, `}
                            {itm.address?.address_line2 && `${itm.address?.address_line2}, `}
                            {itm.address?.city && `${itm.address?.city}, `}
                            {itm.address?.state && `${itm.address?.state}, `}
                            {itm.address?.country && `${itm.address?.country}, `}
                            {itm.address?.zip}
                          </div>

                    ) : (
                      <div className="content-child no-data"> - </div>
                    )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }) : <div className="no-data-found-msg">No relevant data found</div>}
    </div>
  )
}

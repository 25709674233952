import {
  Button, Card, Tabs,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './data.scss'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { parse } from 'query-string';
import {
  getCensusDataAction, getPurpleLabDataForPIForExperienceAction, getPurpleLabDataForSiteSDOHAction,
} from './logic';
import { getDocProfileValuesAction } from '../DoctorProfile/logic';
import Loader from '../../../common/components/Loader';
import { getDeepdiveValuesAction, getPurpleLabDataForSiteExperienceAction } from '../DeepDiveScreen/logic';
import { getPurpleLabDataForPIAction, getPurpleLabDataForPIResetAction } from '../PurpleLabDataForInvestigator/logic';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const columns = [
  {
    title: '',
    dataIndex: '',
    key: '',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: 'National Score',
    dataIndex: 'national_Score',
    key: 'national_Score',
  },
];

export default function DashboardData({ flag }) {
  const dispatch = useDispatch();
  const docProfile = useSelector((state) => state.docProfile);
  const deepDiveData = useSelector((state) => state.deepDive);
  const censusDataResponse = useSelector((state) => state.censusDataResponse);
  const getPurpleLabDataForSiteExperienceResponse = useSelector((redux) => redux.getPurpleLabDataForSiteExperienceResponse || {})

  const [showEthnic, setShowEthnic] = useState(true)
  const [showAge, setShowAge] = useState(false)
  const [showEmpIns, setShowEmpIns] = useState(false)
  const [showIncomeIns, setShowIncomeIns] = useState(false)
  const [docProfileData, setDocProfileData] = useState([])

  const [sdohDataForRace, setSdohDataForRace] = useState([])
  const [sdohDataForEthinicity, setSdohDataForEthinicity] = useState([])
  const [patientGrandTotal, setPatientGrandTotal] = useState('')
  const [totalPatient, setTotalPatient] = useState('')

  const [sdohDataForRaceForSite, setSdohDataForRaceForSite] = useState([])
  const [sdohDataForEthinicityForSite, setSdohDataForEthinicityForSite] = useState([])

  const getPurpleLabDataForPIResponse = useSelector(
    (reduxState) => reduxState.getPurpleLabDataForPIResponse || {},
  );

  const getPurpleLabDataForPIForExperienceResponse = useSelector((reduxState) => reduxState.getPurpleLabDataForPIForExperienceResponse || {})
  const getPurpleLabDataForSiteSDOHResponse = useSelector((reduxState) => reduxState.getPurpleLabDataForSiteSDOHResponse || {})

  useEffect(() => {
    if (docProfile.flag) {
      setDocProfileData(docProfile.data);
    }
  }, [JSON.stringify(docProfile)]);

  useEffect(() => {
    dispatch(getPurpleLabDataForSiteExperienceAction({
      hcotype: 'Experience',
      id: parse(location.search).id,
    }))
  }, [])

  useEffect(() => {
    if (getPurpleLabDataForSiteExperienceResponse.flag) {
      setTotalPatient(getPurpleLabDataForSiteExperienceResponse.data.data.total_patient)
    } else {
      setTotalPatient('')
    }
  }, [JSON.stringify(getPurpleLabDataForSiteExperienceResponse)])

  useEffect(() => {
    if (flag === 'pi') {
      dispatch(getDocProfileValuesAction({ id: (parse(location.search).id), dataset: 'investigators' }));
    } else {
      dispatch(getDeepdiveValuesAction({ id: (parse(location.search).id), dataset: 'centers' }));
    }
    if (flag === 'pi') {
      dispatch(getPurpleLabDataForPIAction({
        hcptype: 'SDOH',
        id: parse(location.search).id,
      }))

      dispatch(getPurpleLabDataForPIForExperienceAction({
        hcptype: 'Experience',
        id: parse(location.search).id,
      }))
    }
    if (flag === 'site') {
      dispatch(getPurpleLabDataForSiteSDOHAction({
        hcotype: 'SDOH',
        id: parse(location.search).id,
        sdoh_attribute_type: 'RACE,ETHNICITY',
      }))
    }
  }, [])

  useEffect(() => {
    if (getPurpleLabDataForPIResponse.flag) {
      if (Object.keys(getPurpleLabDataForPIResponse?.data?.data).length) {
        const sdohDataValuesForRace = getPurpleLabDataForPIResponse?.data?.data.filter((item) => item.type === 'Caucasian' || item.type === 'African-American' || item.type === 'Asian')
        const extractedDataForRace = getPurpleLabDataForPIResponse?.data?.data.filter((item) => item.type === 'Hispanic' || item.type === 'Non-Hispanic')
        setSdohDataForRace(sdohDataValuesForRace)
        setSdohDataForEthinicity(extractedDataForRace)
        dispatch(getPurpleLabDataForPIResetAction())
      } else {
        setSdohDataForRace([])
        setSdohDataForEthinicity([])
      }
    }
  }, [getPurpleLabDataForPIResponse])

  useEffect(() => {
    if (getPurpleLabDataForSiteSDOHResponse.flag) {
      if (getPurpleLabDataForSiteSDOHResponse?.data?.data.length) {
        const raceData = getPurpleLabDataForSiteSDOHResponse?.data?.data.filter((item) => item.type === 'Caucasian' || item.type === 'African-American' || item.type === 'Asian')
        const ethinicityData = getPurpleLabDataForSiteSDOHResponse?.data?.data.filter((item) => item.type === 'Hispanic' || item.type === 'Non-Hispanic')
        setSdohDataForRaceForSite(raceData)
        setSdohDataForEthinicityForSite(ethinicityData)
      } else {
        setSdohDataForEthinicityForSite([])
        setSdohDataForRaceForSite([])
      }
    }
  }, [JSON.stringify(getPurpleLabDataForSiteSDOHResponse)])

  useEffect(() => {
    if (getPurpleLabDataForPIForExperienceResponse.flag) {
      if (getPurpleLabDataForPIForExperienceResponse?.data?.data?.length) {
        setPatientGrandTotal(getPurpleLabDataForPIForExperienceResponse?.data?.data[0]?.patients_grand_total)
      } else {
        setPatientGrandTotal('')
      }
    }
  }, [getPurpleLabDataForPIForExperienceResponse])

  const options = {
    plugins: {
      title: {
        display: false,
        // text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const getData = () => {
    const filteredLabor = censusDataResponse?.data?.labor_force ? censusDataResponse?.data?.labor_force.filter((k) => {
      return Object.keys(k)[0] !== 'total';
    }) : [];
    return {
      labels: filteredLabor.map((k) => {
        const KEY = Object.keys(k)[0];
        return `${KEY} (${k[KEY].total})`;
      }),
      datasets: [
        {
          label: 'Insured',
          data: filteredLabor.map((itm) => {
            const KEY = Object.keys(itm)[0];
            return itm[KEY].insured;
          }),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Not insured',
          data: filteredLabor.map((itm) => {
            const KEY = Object.keys(itm)[0];
            return itm[KEY].non_insured;
          }),

          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    };
  }

  const OnRaceClick = () => {
    // setButtonActive(true)
    setShowEthnic(true)
    if (flag === 'pi') {
      const zip = docProfileData?.primary_organization?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'race_ethnicity',
        zipCode: zip[0],
      }))
    } else {
      const zip = deepDiveData?.data?.address?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'race_ethnicity',
        zipCode: zip[0],
      }))
    }
    setShowAge(false)
    setShowEmpIns(false)
    setShowIncomeIns(false)
  }
  const OnAgeClick = () => {
    // setButtonActive(true)
    if (flag === 'pi') {
      const zip = docProfileData?.primary_organization?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'sex_by_age',
        zipCode: zip[0],
      }))
    } else {
      const zip = deepDiveData?.data?.address?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'sex_by_age',
        zipCode: zip[0],
      }))
    }
    setShowAge(true)
    setShowEthnic(false)
    setShowEmpIns(false)
    setShowIncomeIns(false)
  }

  const OnEmpInsuranceClick = () => {
    // setButtonActive(true)
    if (flag === 'pi') {
      const zip = docProfileData?.primary_organization?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'employment_by_insurance',
        zipCode: zip[0],
      }))
    } else {
      const zip = deepDiveData?.data?.address?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'employment_by_insurance',
        zipCode: zip[0],
      }))
    }
    setShowEmpIns(true)
    setShowEthnic(false)
    setShowAge(false)
    setShowIncomeIns(false)
  }

  const OnIncomeInsClick = () => {
    // setButtonActive(true)
    if (flag === 'pi') {
      const zip = docProfileData?.primary_organization?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'income_by_insurance',
        zipCode: zip[0],
      }))
    } else {
      const zip = deepDiveData?.data?.address?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'income_by_insurance',
        zipCode: zip[0],
      }))
    }
    setShowIncomeIns(true)
    setShowEthnic(false)
    setShowAge(false)
    setShowEmpIns(false)
  }

  const renderAgeGraph = () => {
    const combineData = []
    const label = []
    const male = censusDataResponse?.data?.male_population ? censusDataResponse?.data?.male_population : [];
    male.forEach((itm) => {
      const k = itm.key
      if (k !== 'Total') {
        const fData = censusDataResponse?.data?.female_population?.filter((f) => f.key !== 'Total').find((i) => i.key === k)
        label.push(
          itm.key,
        )
        combineData.push({
          key: k,
          fdata: fData.value,
          mdata: itm.value,
        });
      }
    })
    return {
      labels: label,
      datasets: [
        {
          label: 'Male',
          data: combineData.map((itm) => itm.mdata),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Female',
          data: combineData.map((itm) => itm.fdata),
          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    }
  }

  const getNotLabourData = () => {
    const finalData = censusDataResponse?.data?.non_labor_force ? censusDataResponse?.data?.non_labor_force.filter((f) => Object.keys(f)[0] !== 'total') : []
    const arr = finalData.length ? [finalData[0].insured, finalData[1].non_insured] : []
    return {
      labels: ['Insured', 'Non-insured'],
      datasets: [
        {
          label: 'Insured/Non-insured',
          data: arr.map((k) => k),
          // data: censusDataResponse?.data.non_labor_force,
          backgroundColor: '#D3D800',
        },
        // {
        //   label: 'Not insured',
        //   data: filteredLabor.map((itm) => {
        //     return itm[2].non_insured;
        //   }),

        //   backgroundColor: '#4E565B',
        //   stack: 'Stack 1',
        // },
      ],
    };
  }

  const renderIncomeIns = () => {
    const filteredLaborkeys = Object.keys(censusDataResponse?.data).filter((k) => {
      return k.startsWith('$');
    });

    const filteredLabor = filteredLaborkeys.map((k) => censusDataResponse?.data[k])
    return {
      labels: filteredLabor.map((k, idx) => {
        return `${filteredLaborkeys[idx]} (${k[0].total})`;
      }),
      datasets: [
        {
          label: 'Insured',
          data: filteredLabor.map((itm) => {
            return itm[1].insured;
          }),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Not insured',
          data: filteredLabor.map((itm) => {
            return itm[2].non_insured;
          }),

          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    };
  }

  const renderRaceAndEthnic = () => {
    const combineData = []
    const label = []
    const hispanic = censusDataResponse?.data?.hispanic ? censusDataResponse?.data?.hispanic : [];
    hispanic.forEach((itm) => {
      const k = itm.key
      if (k !== 'Total') {
        const fData = censusDataResponse?.data?.non_hispanic?.filter((f) => f.key !== 'Total').find((i) => i.key === k)
        label.push(
          itm.key,
        )
        combineData.push({
          key: k,
          hdata: itm.value,
          nondata: fData.value,
        });
      }
    })
    return {
      labels: label,
      datasets: [
        {
          label: 'Hispanic',
          data: combineData.map((itm) => itm.hdata),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Non-Hispanic',
          data: combineData.map((itm) => itm.nondata),
          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    }
  }

  useEffect(() => {
    if (flag === 'pi') {
      if (docProfileData?.primary_organization?.zip && docProfileData?.primary_organization?.country === 'United States') {
        const zip = docProfileData?.primary_organization?.zip.split('-')
        dispatch(getCensusDataAction({
          type: 'race_ethnicity',
          zipCode: zip[0],
        }))
      }
    } else if (flag === 'site' && deepDiveData?.data?.address?.zip && deepDiveData?.data?.address?.country === 'United States') {
      const zip = deepDiveData?.data?.address?.zip.split('-')
      dispatch(getCensusDataAction({
        type: 'race_ethnicity',
        zipCode: zip[0],
      }))
    }
  }, [JSON.stringify(docProfile), JSON.stringify(deepDiveData)])

  const renderHeader = () => {
    return (
      <div className="table-row table-header">
        {columns.map((colItm, index) => (
          <div
            className="table-row-item"
            key={index}
          >
            {colItm.title}
          </div>
        ))}
      </div>
    );
  };

  const renderRaceData = () => {
    return sdohDataForRace.map((itm) => {
      return (
        <div className="race-table-data-content">
          <div className="table-itm">{itm?.type}</div>
          <div className="table-itm">{itm?.score?.toFixed(3)} </div>
          <div className="table-itm">{itm?.national_score?.toFixed(3)} </div>
        </div>
      )
    })
  }

  const renderEthinicityData = () => {
    return sdohDataForEthinicity.map((itm) => {
      return (
        <div className="ethnicity-table-data-content">
          <div className="table-itm">{itm?.type}</div>
          <div className="table-itm">{itm?.score.toFixed(3)} </div>
          <div className="table-itm">{itm?.national_score?.toFixed(3)} </div>
        </div>

      )
    })
  }

  const renderRaceDataForSite = () => {
    return sdohDataForRaceForSite.map((itm) => {
      return (
        <div className="race-table-data-content">
          <div className="table-itm">{itm.type}</div>
          <div className="table-itm">{itm?.score?.toFixed(3)} </div>
          <div className="table-itm">{itm.national_score?.toFixed(3)} </div>
        </div>
      )
    })
  }

  const renderEthinicityDataForSite = () => {
    return sdohDataForEthinicityForSite.map((itm) => {
      return (
        <div className="ethnicity-table-data-content">
          <div className="table-itm">{itm.type}</div>
          <div className="table-itm">{itm.score?.toFixed(3)} </div>
          <div className="table-itm">{itm.national_score?.toFixed(3)} </div>
        </div>

      )
    })
  }

  const renderLabel = (key) => {
    if (key === 'Race') {
      return 'Race'
    } if (key === 'hispanic') {
      return 'Hispanic'
    }
    return 'Non-Hispanic'
  }

  return (
    <>
      <Loader loading={docProfile.loading || deepDiveData.loading || getPurpleLabDataForPIForExperienceResponse.loading || getPurpleLabDataForPIResponse.loading || getPurpleLabDataForSiteSDOHResponse.loading} error={docProfile.error || deepDiveData.error}>
        {
            flag === 'pi' && (
              <div className="puplelab-sdoh-data-for-investigator">
                <div className="puplelab-sdoh-data-for-investigator-title"> PurpleLab SDOH Data</div>
                <div className="puplelab-sdoh-data-for-investigator-total-patient">Total Patients: &nbsp; {patientGrandTotal !== '' ? patientGrandTotal : '-'}</div>
                <div className="puplelab-sdoh-data-for-investigator-content">
                  <div className="puplelab-sdoh-data-for-investigator-content-race">
                    <div className="main-table-header-title">Race</div>
                    <div className="table-content">
                      <div className="race-table-header">{renderHeader()}</div>
                      <div className="race-table-data">
                        {sdohDataForRace.length ? renderRaceData() : <div className="race-table-data no-data-found-msg-for-PI">No relevent data found</div>}
                      </div>
                    </div>
                  </div>
                  <div className="puplelab-sdoh-data-for-investigator-content-ethnicity">
                    <div className="main-table-header-title">Ethnicity</div>
                    <div className="table-content">
                      <div className="ethnicity-table-header">{renderHeader()}</div>
                      <div className="ethnicity-table-data">
                        {sdohDataForEthinicity.length ? renderEthinicityData() : <div className="race-table-data no-data-found-msg-for-PI">No relevent data found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        }
        {
            flag === 'site' && (
              <div className="puplelab-sdoh-data-for-investigator center-deatils">
                <div className="puplelab-sdoh-data-for-investigator-title"> PurpleLab SDOH Data</div>
                <div className="puplelab-sdoh-data-for-investigator-total-patient">Total Patients: &nbsp; {totalPatient !== '' ? totalPatient : '-'}</div>
                <div className="puplelab-sdoh-data-for-investigator-content">
                  <div className="puplelab-sdoh-data-for-investigator-content-race">
                    <div className="main-table-header-title">Race</div>
                    <div className="table-content">
                      <div className="race-table-header">{renderHeader()}</div>
                      <div className="race-table-data">
                        {sdohDataForRaceForSite.length ? renderRaceDataForSite() : <div className="race-table-data no-data-found-msg-for-PI">No relevent data found</div>}
                      </div>
                    </div>
                  </div>
                  <div className="puplelab-sdoh-data-for-investigator-content-ethnicity">
                    <div className="main-table-header-title">Ethnicity</div>
                    <div className="table-content">
                      <div className="ethnicity-table-header">{renderHeader()}</div>
                      <div className="ethnicity-table-data">
                        {sdohDataForEthinicityForSite.length ? renderEthinicityDataForSite() : <div className="race-table-data no-data-found-msg-for-PI">No relevent data found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        }
        <div className="census-data-content">
          <div className={flag === 'site' ? 'census-data-content-title center-deepdive' : 'census-data-content-title'}>US Census Data</div>
          {(docProfileData?.primary_organization?.zip && docProfileData?.primary_organization?.country === 'United States')
      || (deepDiveData?.data?.address?.zip && deepDiveData?.data?.address?.country === 'United States') ? (
        <div className="census-data data-wrap">
          <div className="card-1 census-data-tabs">
            <div className="tabs-sidebar">
              <Button className={showEthnic ? 'active' : 'race-btn'} onClick={OnRaceClick}>Race & Ethnicity</Button>
              <Button className={showAge ? 'active' : 'age-btn'} onClick={OnAgeClick}>Age & Sex</Button>
              <Button className={showEmpIns ? 'active' : 'emp-btn'} onClick={OnEmpInsuranceClick}>Employment by insurance</Button>
              <Button className={showIncomeIns ? 'active' : 'income-btn'} onClick={OnIncomeInsClick}>Income by insurance</Button>
            </div>

          </div>

          {showEthnic && (
          <>
            <div className="census-data-chart-graph">
              <div className="census-data-chart">
                <div className="card-header">
                  <div className="card-title">All</div>
                </div>
                <div className="census-data-chart-content">
                  {censusDataResponse.flag && (
                  //   <BarChart
                  //     data={censusDataResponse?.data?.all.filter((f) => f.key !== 'Race')}
                  // // width={(censusDataResponse?.data?.all?.length * 30) > 900 ? censusDataResponse?.data?.all.length * 30 : 900}
                  //     height={280}
                  //     width={280}
                  //     top={60}
                  //     bottom={60}
                  //     left={50}
                  //     right={50}
                  //   />
                  <Bar options={options} data={renderRaceAndEthnic()} />
                  )}
                </div>
              </div>
            </div>
            <div className="census-data-content-table">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="All" key="1">
                  <div>
                    {censusDataResponse.flag && censusDataResponse?.data?.all?.map((itm) => {
                      return (
                        <div className="all-wrap">
                          <div className="key-wrap">
                            {renderLabel(itm.key)}
                          </div>
                          <div className="value-wrap">
                            {itm.value}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Non Hispanic" key="2">
                  <div>
                    {censusDataResponse.flag && censusDataResponse?.data?.non_hispanic?.map((itm) => {
                      return (
                        <div className="all-wrap">
                          <div className="key-wrap">
                            {itm.key}
                          </div>
                          <div className="value-wrap">
                            {itm.value}
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Hispanic" key="3">
                  <div>
                    {censusDataResponse.flag && censusDataResponse?.data?.hispanic?.map((itm) => {
                      return (
                        <div className="all-wrap">
                          <div className="key-wrap">
                            {itm.key}
                          </div>
                          <div className="value-wrap">
                            {itm.value}
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
          )}
          {showAge && (
          <>
            <div>
              <Card>
                {censusDataResponse.flag
            && <Bar options={options} data={renderAgeGraph()} />}
              </Card>
            </div>
            <div className="census-data-content-table">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Male" key="1">
                  <div>
                    {censusDataResponse.flag && censusDataResponse?.data?.male_population?.map((itm) => {
                      return (
                        <div className="all-wrap">
                          <div className="key-wrap">
                            {itm.key}
                          </div>
                          <div className="value-wrap">
                            {itm.value}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Female" key="2">
                  <div>
                    {censusDataResponse.flag && censusDataResponse?.data?.female_population?.map((itm) => {
                      return (
                        <div className="all-wrap">
                          <div className="key-wrap">
                            {itm.key}
                          </div>
                          <div className="value-wrap">
                            {itm.value}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
          )}
          {showEmpIns && (
          <>
            <div>
              <Card>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Labor force" key="1">
                    <div>
                      {censusDataResponse.flag
            && <Bar options={options} data={getData()} />}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Not in labor force" key="2">
                    <div>
                      {censusDataResponse.flag
            && <Bar options={options} data={getNotLabourData()} />}
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </Card>
            </div>
          </>
          ) }
          {showIncomeIns && (
          <>
            <div>
              <Card>
                {censusDataResponse.flag
            && <Bar options={options} data={renderIncomeIns()} />}
              </Card>
            </div>
          </>
          ) }
        </div>
            ) : <div className="no-data-found-msg">No relevant data found</div>}
        </div>
      </Loader>
    </>
  )
}

import { Button } from 'antd';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

export default function InputWithCopy({ text, type }) {
  const getTextData = () => {
    return text[type].join(',')
  }
  return (
    <div className="copy-text">
      <CopyToClipboard text={getTextData()}>
        <Button className="copy-text-btn" title="Copy Text" type="default" />
      </CopyToClipboard>
    </div>
  )
}

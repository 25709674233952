import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_PAYMENT_DATA = 'GET_PAYMENT_DATA';
const GET_PAYMENT_DATA_MORE = 'GET_PAYMENT_DATA_MORE';
const GET_PAYMENT_DATA_MORE_SUCCESS = 'GET_PAYMENT_DATA_MORE_SUCCESS'
const GET_PAYMENT_DATA_CANCEL = 'GET_PAYMENT_DATA_CANCEL';
const GET_PAYMENT_DATA_SUCCESS = 'GET_PAYMENT_DATA_SUCCESS';
const GET_PAYMENT_DATA_FAILURE = 'GET_PAYMENT_DATA_FAILURE';
const GET_PAYMENT_DATA_RESET = 'GET_PAYMENT_DATA_RESET';

// Auto Complete cancel action
export const getPaymentDataCancelAction = () => ({
  type: GET_PAYMENT_DATA_CANCEL,
});

export const getPaymentDataResetAction = () => ({
  type: GET_PAYMENT_DATA_RESET,
});
  // Auto Complete action
export const getPaymentDataAction = (payload) => ({
  type: GET_PAYMENT_DATA,
  payload,
});

export const getPaymentMoreDataAction = (payload) => ({
  type: GET_PAYMENT_DATA_MORE,
  payload,
});

// Auto Complete Success action
const getPaymentDataSuccess = (payload) => ({
  type: GET_PAYMENT_DATA_SUCCESS,
  payload,
});

const getPaymentDataMoreSuccess = (payload) => ({
  type: GET_PAYMENT_DATA_MORE_SUCCESS,
  payload,
});

export const getPaymentDataEpic = (actions$) => actions$.pipe(
  ofType(GET_PAYMENT_DATA),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/getPaymentData?${stringify(action.payload)}`, 'get'),
  ).pipe(
    map((res) => getPaymentDataSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_PAYMENT_DATA_CANCEL))),
    catchError((err) => of({
      type: GET_PAYMENT_DATA_FAILURE,
      payload: err,
    })),
  )),
);

export const getPaymentMoreDataEpic = (actions$) => actions$.pipe(
  ofType(GET_PAYMENT_DATA_MORE),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/getPaymentData?${stringify(action.payload)}`, 'get'),
  ).pipe(
    map((res) => getPaymentDataMoreSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_PAYMENT_DATA_CANCEL))),
    catchError((err) => of({
      type: GET_PAYMENT_DATA_FAILURE,
      payload: err,
    })),
  )),
)

export function getPaymentDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PAYMENT_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_PAYMENT_DATA_SUCCESS: {
      return {
        data: action.payload.response,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_PAYMENT_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    case GET_PAYMENT_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.response.data,
          ],
          total: action.payload.response.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PAYMENT_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state;
  }
}
